<!--  -->
<template>
  <div class="activity-detail">
    <h3 class="title">{{ detail.title }}</h3>
    <p class="date">{{ detail.year }}-{{ detail.date }}</p>
    <div class="rich-text" v-html="detail.content"></div>
  </div>
</template>

<script>
import { fetchActivityDetail } from "@/api/api.js";
export default {
  name: "activity-detail",
  components: {},
  data() {
    return {
      detail: {},
    };
  },
  computed: {},
  watch: {},
  methods: {
    async getData() {
      try {
        let res = await fetchActivityDetail({
          params: {
            id: this.$route.query.id,
          },
        });
        this.detail = res.data.data;
        console.log("res", res);
      } catch (err) {
        console.log("err", err);
      }
    },
  },
  created() {
    this.getData();
  },
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.activity-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  .title{
    margin: 70px 0 0;
  }
  .date {
    color: #999999;
  }
}
</style>
